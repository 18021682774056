import React, { useEffect } from 'react';
import useAuth from '../Auth/hook_useAuth';
import Api from '../Auth/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function Login() {

    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [remember, setRemember] = React.useState(false);
    const { signin } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await Api.post("/login", { email, password });
            signin({ token: response.data.token, nome_completo: response.data.nome_completo, nivel: response.data.nivel, foto: response.data.foto }, remember);
            navigate("/dashboard");
        } catch (error) {
            toast.error('Seu código ou senha estão incorretos.', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    useEffect(() => {
        // Remover token apenas se não houver "remember" salvo
        if (!localStorage.getItem("remember")) {
            localStorage.removeItem("user_token");
        }
    }, []);


    return (
        <>
            <ToastContainer />

            <main className="d-flex w-100">
                <div className="container d-flex flex-column">
                    <div className="row vh-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">

                                <div className="text-center mt-4">
                                    <h1 className="h2">Bem vindo de volta!</h1>
                                    <p className="lead">
                                        Entre com sua conta para continuar.
                                    </p>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-sm-3">
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label">E-mail</label>
                                                    <input className="form-control form-control-lg" value={email} onChange={e => setEmail(e.target.value)} type="email" name="email" placeholder="Digite seu email" />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Senha</label>
                                                    <input className="form-control form-control-lg" value={password} onChange={e => setPassword(e.target.value)} type="password" name="password" placeholder="Digite sua senha" />
                                                </div>
                                                <div>
                                                    <div className="form-check align-items-center">
                                                        <input id="customControlInline" type="checkbox" className="form-check-input" checked={remember} onChange={(e) => setRemember(e.target.checked)} name="remember-me" />
                                                        <label className="form-check-label text-small" htmlFor="customControlInline">Lembrar</label>
                                                    </div>
                                                </div>
                                                <div className="d-grid gap-2 mt-3">
                                                    <button type="submit" className="btn btn-lg btn-primary">Entrar</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Login;