import React, { useState, useEffect } from 'react';
import Api from '../Auth/Api';
import { Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Heatmaps.css'; // Novo arquivo CSS

function Heatmaps() {
    const [map, setMap] = useState('');
    const [team, setTeam] = useState('');
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const maps = ['bind', 'haven', 'split', 'ascent', 'icebox', 'breeze', 'fracture', 'pearl', 'lotus'];

    useEffect(() => {
        fetchSeasons();
    }, []);

    const fetchSeasons = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("user_token"));
            const response = await Api.get('api/seasons', {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            const currentDate = new Date();
            const customEntry = { displayName: "Tudo", uuid: null };
            const filteredSeasons = [customEntry, ...response.data.data
                .filter(season => new Date(season.startTime) <= currentDate)
                .sort((a, b) => new Date(b.startTime) - new Date(a.startTime))];
            
            setSeasons(filteredSeasons);
            
            // Set current season
            const acts = response.data.data.filter(season => season.type === "EAresSeasonType::Act");
            const currentAct = acts.find(act => 
                new Date(act.startTime) <= currentDate && 
                new Date(act.endTime) >= currentDate
            );
            setSelectedSeason(currentAct);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchHeatmap = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const token = JSON.parse(localStorage.getItem("user_token"));
            const params = new URLSearchParams({
                map: map,
                ...(team && { team }),
                ...(selectedSeason?.uuid && { seasonUuid: selectedSeason.uuid })
            });

            const response = await Api.get(`api/heatmap?${params}`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob'
            });

            if (response.data.size === 0) {
                setError("Não há dados suficientes para gerar o heatmap");
                setImageUrl(null);
                return;
            }

            const imageObjectURL = URL.createObjectURL(response.data);
            setImageUrl(imageObjectURL);
        } catch (error) {
            console.error(error);
            setImageUrl(null);
            setError("Ocorreu um erro ao gerar o heatmap");
            toast.error('Falha ao carregar o heatmap. Tente novamente mais tarde.', {
                position: "top-right",
                autoClose: 5000,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = async () => {
        if (!map) {
            return;
        }
        await fetchHeatmap();
    };

    return (
        <main className="content">
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3 fade-in">Heatmaps</h1>
                
                <div className="card shadow-sm hover-shadow">
                    <div className="card-body">
                        <div className="alert alert-info mb-4 fade-in">
                            <h5 className="d-flex align-items-center">
                                <i className="fas fa-info-circle me-2 pulse"></i>
                                Sobre os Heatmaps
                            </h5>
                            <p className="mb-0">
                                Os heatmaps mostram as posições onde você conseguiu eliminar outros jogadores. 
                                Áreas mais intensas indicam maior frequência de eliminações naquela região.
                            </p>
                            <p className="mb-0 mt-2">
                                Selecione um mapa e, opcionalmente, filtre por lado (ataque/defesa) e temporada específica.
                            </p>
                        </div>

                        <div className="row mb-4 controls-container">
                            <div className="col-md-4 control-item">
                                <label className="form-label">Mapa</label>
                                <select 
                                    className="form-select custom-select"
                                    value={map} 
                                    onChange={(e) => setMap(e.target.value)}
                                >
                                    <option value="">Selecione um mapa</option>
                                    {maps.map(m => (
                                        <option key={m} value={m}>
                                            {m.charAt(0).toUpperCase() + m.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-4 control-item">
                                <label className="form-label">Lado</label>
                                <select 
                                    className="form-select custom-select"
                                    value={team} 
                                    onChange={(e) => setTeam(e.target.value)}
                                >
                                    <option value="">Ambos os lados</option>
                                    <option value="red">Ataque</option>
                                    <option value="blue">Defesa</option>
                                </select>
                            </div>
                            <div className="col-md-4 control-item">
                                <label className="form-label">Temporada</label>
                                <select 
                                    className="form-select custom-select"
                                    value={selectedSeason?.uuid || ''}
                                    onChange={(e) => {
                                        const season = seasons.find(s => s.uuid === e.target.value);
                                        setSelectedSeason(season);
                                    }}
                                >
                                    {seasons.map(season => (
                                        <option key={season.uuid || 'all'} value={season.uuid || ''}>
                                            {season.displayName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="text-center mb-4 fade-in">
                            <button 
                                className="btn btn-primary btn-lg custom-button"
                                onClick={handleSearch}
                                disabled={!map || isLoading}
                            >
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        <span className="button-text">Gerando Heatmap...</span>
                                    </>
                                ) : (
                                    <span className="button-text">Gerar Heatmap</span>
                                )}
                            </button>
                        </div>
                        
                        <div className="text-center heatmap-container">
                            {!map && !imageUrl && !error && (
                                <div className="alert alert-info fade-in">
                                    <i className="fas fa-info-circle me-2 bounce"></i>
                                    Selecione um mapa para gerar o heatmap
                                </div>
                            )}

                            {error && (
                                <div className="alert alert-warning fade-in shake">
                                    <i className="fas fa-exclamation-triangle me-2"></i>
                                    {error}
                                </div>
                            )}
                            
                            {imageUrl && (
                                <div className="heatmap-wrapper fade-in">
                                    <img 
                                        src={imageUrl} 
                                        alt="Heatmap" 
                                        className="img-fluid rounded shadow-lg heatmap-image"
                                    />
                                    <div className="mt-3 text-muted heatmap-info">
                                        <small>
                                            <span className="info-item">
                                                <i className="fas fa-map-marker-alt me-1"></i>
                                                Mapa: {map.charAt(0).toUpperCase() + map.slice(1)}
                                            </span>
                                            <span className="info-separator">•</span>
                                            <span className="info-item">
                                                <i className="fas fa-shield-alt me-1"></i>
                                                Lado: {team ? (team === 'blue' ? 'Defesa' : 'Ataque') : 'Ambos'}
                                            </span>
                                            <span className="info-separator">•</span>
                                            <span className="info-item">
                                                <i className="fas fa-clock me-1"></i>
                                                Temporada: {selectedSeason?.displayName || 'Todas'}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </main>
    );
}

export default Heatmaps;
