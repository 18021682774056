import React, { createContext, useState, useEffect } from "react";
import { decodeJWT } from '../Components/jwtUtils';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("user_token");
        if (token) {
            const decodedToken = decodeJWT(JSON.parse(token));
            if (decodedToken && decodedToken.nivel) {
                setUser({
                    token: JSON.parse(token),
                    nome_completo: decodedToken.nome_completo,
                    nivel: decodedToken.nivel,
                    foto: decodedToken.foto
                });
            }
        }
    }, []);

    const signin = (userData, remember) => {
        setUser(userData);
        localStorage.setItem("user_token", JSON.stringify(userData.token));
        if (remember) {
            localStorage.setItem("remember", "true");
        }
    };

    const signout = () => {
        setUser(null);
        localStorage.removeItem("user_token");
        localStorage.removeItem("remember");
    };

    return (
        <AuthContext.Provider
            value={{ user, signed: !!user, signin, signout }}
        >
            {children}
        </AuthContext.Provider>
    );
};